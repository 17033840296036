import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { themeSettings } from "theme";
import Layout from "scenes/layout";
import Dashboard from "scenes/dashboard";
import Users from "scenes/users";
import Login from "scenes/authentication";
import Regions from "scenes/region";
import Service from "scenes/service";
import Packet from "scenes/packet";
import Operator from "scenes/operator";
import ProtectedRoute from "components/ProtectedRoute";
import RequireAuth from "features/auth/RequireAuth";
import AddService from "scenes/addService";
import { ServerResponseNotifierProvider } from "features/context/ServerResponseNotifierProvider";
import { ServerResponseNotifier } from "components/ServerResponseNotifier";
import AddPayment from "scenes/addPayment";
import AddPaymentWithId from "scenes/addPayment/AddPaymentWithId";
import Payments from "scenes/payments";
import Offers from "scenes/offers";
import { getClaimByKey } from "utils/jwtUtils";
import OperatorPayments from "scenes/payments/OperatorPaments";
import Withdrawals from "scenes/withdrawal";
import PrintPage from "components/printer/PrintPage";
import Items from "scenes/items";
import PurchaseInvoice from "scenes/purchaseInvoice";
import Customer from "scenes/customer";
import SaleInvoice from "scenes/saleInvoice";
import WatchActivity from "scenes/watchActivity";

function App() {
  const mode = useSelector((state) => state.auth.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const role = getClaimByKey("role");

  return (
    <ServerResponseNotifierProvider>
      <div className="app">
        <BrowserRouter>
          <ServerResponseNotifier />
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Routes>
              <Route element={<RequireAuth />}>
                <Route path="/" element={<Dashboard />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/services" element={<Service />} />
                <Route path="/packets" element={<Packet />} />
                <Route path="/operators" element={<Operator />} />
                <Route path="/users" element={<Users />} />
                <Route path="/customers" element={<Customer />} />
                <Route path="/offers" element={<Offers />} />
                <Route path="/withdrawals" element={<Withdrawals />} />
                <Route path="/regions" element={<Regions />} />
                <Route path="/services/add-service" element={<AddService />} />
                <Route path="/payments/add-payment" element={<AddPayment />} />
                <Route path="/payments" element={role==="Admin" ?<Payments />:<OperatorPayments/>} />
                <Route path="/payments/add-payment/:id" element={<AddPaymentWithId />}/>
                <Route path="/print" element={<PrintPage />} />
                <Route path="/items" element={<Items />} />
                <Route path="/purchase-invoice" element={<PurchaseInvoice />} />
                <Route path="/sale-invoice" element={<SaleInvoice />} />
                <Route path="/activities" element={<WatchActivity />} />
              </Route>
              <Route path="/login" element={<Login />} />
            </Routes>
          </ThemeProvider>
        </BrowserRouter>
      </div>
    </ServerResponseNotifierProvider>
  );
}

export default App;
