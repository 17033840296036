import React, { useState, useEffect } from "react";
import { Autocomplete, Box, TextField } from "@mui/material";
import PaginationTable from "components/PaginationTable";
import { useGetServicesForActivityQuery } from "features/services/servicesApiSlice";
import { useGetAutoCompleteUsersMutation } from "features/users/usersApiSlice";

const WatchActivity = () => {
  const [results, setResults] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const [getAutoCompleteUsers] = useGetAutoCompleteUsersMutation();

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState([]);

  const {
    data,
    isLoading,
    refetch: refetchData,
  } = useGetServicesForActivityQuery({
    pageNumber: pageNumber + 1,
    pageSize,
    filters: filters,
  });

  useEffect(() => {
    if (data) {
      setTableData(data);
    }
  }, [data]);

  useEffect(() => {
    refetchData({ pageNumber, pageSize, filters });
  }, [pageNumber, pageSize, filters]);

  const handlePaginationModelChange = (model) => {
    setPageNumber(model.page);
    setPageSize(model.pageSize);
  };

  useEffect(() => {
    const timerId = setTimeout(async () => {
      if (searchTerm) {
        const { data: newResults } = await getAutoCompleteUsers({
          searchString: searchTerm,
          topRecords: 10,
        });
        setResults(newResults || []);
      } else {
        setResults([]);
      }
    }, 500);

    return () => {
      clearTimeout(timerId);
    };
  }, [searchTerm]);

  const handleChange = (e, newValue) => {
    const { name, value } = e.target;
    if (newValue) {
      let newFilters = [];
      newFilters.push({
        columnName: "clientId",
        value: newValue.id,
        operation: 3,
      });
      setFilters(newFilters);
      setSelectedUser(newValue.name);
    } else {
      setFilters([]);
      setSelectedUser("");
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "Id",
      flex: 0.5,
    },
    {
      field: "serviceName",
      headerName: "Service",
      flex: 0.3,
    },
    {
      field: "packetName",
      headerName: "Packet",
      flex: 0.5,
    },
    {
      field: "clientName",
      headerName: "Client",
      flex: 0.4,
    },
    {
      field: "typeIdentifier",
      headerName: "Type Identifier",
      width:150,
    },
    {
      field: "endExpiryDate",
      headerName: "Expiry",
      flex: 0.5,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.3,
      renderCell: (params) => (
        <span style={{ color: params.value === "Online" ? "green" : "red" }}>
          {params.value}
        </span>
      ),
    },
    {
      field: "state",
      headerName: "State",
      flex: 0.3,
    },
    {
      field: "lastActivity",
      headerName: "Last Activity",
      width: 150,
    },
    {
      field: "ipAddress",
      headerName: "IP Address",
      flex: 0.3,
      renderCell: (params) => (
        <a
          href={`http://${params.value}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "inherit", textDecoration: "none" }}
        >
          {params.value}
        </a>
      ),
    },
    {
      field: "currentWatching",
      headerName: "Current Watching",
      flex: 0.4,
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Box display={"flex"} justifyContent={"center"} paddingTop={3}>
        <Autocomplete
          id="user-search"
          name="users"
          options={results}
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.name
          }
          style={{ width: "35%" }}
          clearOnEscape={true}
          onChange={handleChange}
          value={selectedUser}
          noOptionsText="No user was found"
          inputValue={searchTerm}
          onInputChange={(event, newInputValue) => {
            setSearchTerm(newInputValue);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Search for user" variant="outlined" />
          )}
        />
      </Box>

      <PaginationTable
        handleOpenForm={null}
        title={"Payments"}
        data={tableData.data}
        isLoading={isLoading}
        columns={columns}
        onPageChange={handlePaginationModelChange}
        totalCount={tableData.totalCount}
        currentPage={pageNumber}
        pageSize={pageSize}
        fixedMinWith={"1200px"}
      />
    </Box>
  );
};

export default WatchActivity;
