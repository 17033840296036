import { apiSlice } from "app/api/apiSlice";

export const servicesApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getServiceList: build.query({
      query: () => "service/list",
    }),
    getMainServicesList: build.mutation({
      query: (serviceId)=> `service/${serviceId}/main-list`,
      method:"GET"
    }),

    getServicesForActivity: build.query({
      query: (args) => {
          let requestBody = {
              fromSearchAll: false,
              pageNumber: args.pageNumber,
              pageSize: args.pageSize,
              filters: []
          };
  
          if (args.filters && args.filters.length >0) {
              requestBody.filters = args.filters;
          }
  
          return {
              url: 'service/get-all/activities',
              method: 'POST',
              body: requestBody
          };
      },
  }),
  }),
});

export const { useGetServiceListQuery,useGetMainServicesListMutation,useGetServicesForActivityQuery } = servicesApiSlice;
